<template>
  <ui-wrap-modal
    v-bind="$attrs"
    size="lg"
  >
    <ui-typography
      variant="h3"
      tag="h3"
      class="lg:mb-5 mb-2.5 text-center"
      text="Update Interests"
    />
    <common-forms-update-interests
      v-bind="$props"
      @success="onSuccess"
      @close="onClose"
    />
  </ui-wrap-modal>
</template>

<script setup lang="ts">
  const emit = defineEmits(['update:modelValue']);
  type Props = {
    source: User | Room;
    interestsIds: number[];
  };
  defineProps<Props>();
  const { onSuccess, onClose } = createModalState(emit);
</script>
