<template>
  <form @submit="onSubmit">
    <common-forms-parts-interests-list
      :source="source"
      :data="interests"
      class="mb-7"
    />
    <div class="flex gap-3">
      <ui-button
        full
        text="Cancel"
        variant="secondary-outline"
        @click="$emit('close')"
      />
      <ui-button
        full
        text="Save"
        :loading="isSubmitting"
        type="submit"
      />
    </div>
  </form>
</template>

<script setup lang="ts">
  import { InterestableType, type TInterestableType } from '~/globals/interest';

  type Props = {
    source: User | Room;
    interestableType?: TInterestableType;
    interestsIds: number[];
  };
  const props = withDefaults(defineProps<Props>(), {
    interestableType: InterestableType.USER
  });
  const emit = defineEmits(['close', 'success']);

  const apiRoutes = useApiRoutes();
  const interestsStore = useInterestsStore();

  const interests = computed(() => {
    return props.interestableType === InterestableType.USER
      ? interestsStore.profilePrivateInterests
      : interestsStore.roomPrivateInterests;
  });

  const initialValues = computed(() => ({
    interest_ids: props.interestsIds || []
  }));
  const { handleSubmit, isSubmitting, values } = useForm({
    initialValues
  });

  const onSubmit = handleSubmit(
    useSubmitHandler(
      form => {
        return apiRoutes.profile.update(form);
      },
      initialValues,
      {
        async onSuccess(data) {
          // there is no interest_ids field in response
          emit('success', { ...data, ...values });
        },
        isCleanData: true,
        successToast: true
      }
    )
  );
</script>
